// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ItemList_list__trC-l {
    list-style: none;
    align-items: center;
    margin: 0;
    padding: 0;
}
.ItemList_button__v6Q9G{
    border: none;
    background: none;
    padding: 0;
    font-size: 20px;
    min-height: 40%;
}

.ItemList_nothingbutton__a8mcI:hover{
    border: none;
    background: none;
    padding: 0;
    color: black;
    font-size: 25px;
    cursor: pointer;
}

.ItemList_icon__JKRmg{
    color: black;
    font-size: 20px;
    cursor: pointer;
}

.ItemList_icon__JKRmg:hover{
    color: black;
    font-size: 25px;
    cursor: pointer;
}

.ItemList_iconFill__YiCX1{
    color: black;
}

.ItemList_namedocs__TrRim{
    color: black;
    font-size: 20px;
    cursor: pointer;
    padding: 5px 5px;
    border: none;
    border-radius: 1px;
    transition: background-color 0.3s, transform 0.3s, opacity 0.3s;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.ItemList_namedocs__TrRim:hover{
    opacity: 0.5;
    font-size: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/transparency/ItemList.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,SAAS;IACT,UAAU;AACd;AACA;IACI,YAAY;IACZ,gBAAgB;IAChB,UAAU;IACV,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,UAAU;IACV,YAAY;IACZ,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,+DAA+D;IAC/D,UAAU;IACV,6BAA6B;AACjC;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".list {\r\n    list-style: none;\r\n    align-items: center;\r\n    margin: 0;\r\n    padding: 0;\r\n}\r\n.button{\r\n    border: none;\r\n    background: none;\r\n    padding: 0;\r\n    font-size: 20px;\r\n    min-height: 40%;\r\n}\r\n\r\n.nothingbutton:hover{\r\n    border: none;\r\n    background: none;\r\n    padding: 0;\r\n    color: black;\r\n    font-size: 25px;\r\n    cursor: pointer;\r\n}\r\n\r\n.icon{\r\n    color: black;\r\n    font-size: 20px;\r\n    cursor: pointer;\r\n}\r\n\r\n.icon:hover{\r\n    color: black;\r\n    font-size: 25px;\r\n    cursor: pointer;\r\n}\r\n\r\n.iconFill{\r\n    color: black;\r\n}\r\n\r\n.namedocs{\r\n    color: black;\r\n    font-size: 20px;\r\n    cursor: pointer;\r\n    padding: 5px 5px;\r\n    border: none;\r\n    border-radius: 1px;\r\n    transition: background-color 0.3s, transform 0.3s, opacity 0.3s;\r\n    opacity: 1;\r\n    transition: opacity 0.3s ease;\r\n}\r\n\r\n.namedocs:hover{\r\n    opacity: 0.5;\r\n    font-size: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `ItemList_list__trC-l`,
	"button": `ItemList_button__v6Q9G`,
	"nothingbutton": `ItemList_nothingbutton__a8mcI`,
	"icon": `ItemList_icon__JKRmg`,
	"iconFill": `ItemList_iconFill__YiCX1`,
	"namedocs": `ItemList_namedocs__TrRim`
};
export default ___CSS_LOADER_EXPORT___;
