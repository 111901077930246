import styles from './Transparency.module.css'
import {Link} from 'react-router-dom'
import React from 'react';
import { FaHome } from "react-icons/fa";
import TransparencyListExpansive from '../components/transparency/TransparencyExpansiveList'

function Transparency() {
    return(

        <div>
            <div class={styles.path_border}>
                <li class={styles.path}>
                    <Link to="/"><FaHome/>Inicio</Link><span> / Transparencia</span>
                </li>
            </div>
            <li className={styles.transparency_background}>

                <div className={styles.transparency_container}>
                    <h1>
                        Documentos Intitucionais
                    </h1>
                    <div className={styles.cont_list_container}>
                        <TransparencyListExpansive/>
                    </div>

                </div>

            </li>
        </div>
        
    )

}

export default Transparency