import React, { useState } from 'react';
import styles from './TransparencyExpansiveList.module.css';
import ExpansiveList from './ExpansiveList';
import RouletteBoard from './RouletteBoard';
import RouletteCollaborators from './RouletteCollaborators';
import RouletteBoardPartners from './RouletteBoardPartners';

const TransparencyListExpansive = () => {
  const [expandido, setExpandido] = useState({
    documentos: false,
    administracao: false,
    contabilidade: false,
    planoAcao: false,
    parcerias: false,
  });

  const handleToggleExpansao = (secao) => {
    setExpandido((prevExpandido) => ({
      ...Object.fromEntries(Object.keys(prevExpandido).map(key => [key, false])),
      [secao]: !prevExpandido[secao],
    }));
  };

  const getButtonClass = (secao) => {
    return expandido[secao] ? styles.selected_button : styles.button_layout;
  };

  return (
    <div>
      <div>
        <button className={getButtonClass('documentos')} onClick={() => handleToggleExpansao('documentos')}>Documentos</button>
        <button className={getButtonClass('administracao')} onClick={() => handleToggleExpansao('administracao')}>Administração</button>
        <button className={getButtonClass('contabilidade')} onClick={() => handleToggleExpansao('contabilidade')}>Contabilidade</button>
        <button className={getButtonClass('planoAcao')} onClick={() => handleToggleExpansao('planoAcao')}>Plano de Ação</button>
        <button className={getButtonClass('parcerias')} onClick={() => handleToggleExpansao('parcerias')}>Parcerias</button>
      </div>

      <div>
        {expandido.documentos && (
          <ul className={styles.expansive_layout}>
            <li style={{ margin: '0 10px' }}>Conteudo Documentos
              <p className={styles.custom_button}>
                <ExpansiveList/>
              </p>
            </li>
          </ul>
        )}

        {expandido.administracao && (
          <ul className={styles.expansive_layout}>
            <li style={{ margin: '0 10px' }}>
              <div className={styles.centerroulette}>
                <div>
                  <h1>Diretoria Eleita</h1>
                  <p>2023 A 2025</p>
                  <RouletteBoard/>

                  <h1 className={styles.title}>QUADRO DE FUNCIONÁRIAS:</h1>
                  <RouletteCollaborators/>
                </div>

              </div>
            </li>
          </ul>
        )}

        {expandido.contabilidade && (
          <ul className={styles.expansive_layout}>
            <li style={{ margin: '0 10px' }}>Conteudo Contabilidade</li>
          </ul>
        )}

        {expandido.planoAcao && (
          <ul className={styles.expansive_layout}>
            <li style={{ margin: '0 10px' }}>Conteudo Plano de Ação</li>
          </ul>
        )}

        {expandido.parcerias && (
          <ul className={styles.expansive_layout}>
            <li style={{ margin: '0 10px' }}></li>
            <div className={styles.centerroulette}>
                <div>
                  <h1>Empresas Parceiras</h1>
                  <RouletteBoardPartners/>
                </div>

              </div>
          </ul>
        )}
      </div>
    </div>
  );
};

export default TransparencyListExpansive;