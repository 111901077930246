// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../img/BackGround.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_home_background__TbZjr {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.Home_home_container__n9\\+KO {
    background-color: #5b95f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 12em auto;
    padding: 2.5em;
    min-width: 75%;
    max-width: 80%;
    flex-grow: 1;
}

.Home_home_container__n9\\+KO h1 {
    font-size: 2.5em;
    margin-bottom: 1em;
}

.Home_home_container__n9\\+KO h1 span {
    color: rgb(35, 131, 227);
    padding: 0.2em;
    background-color: #282c34;
}

.Home_home_container__n9\\+KO p {
    margin-bottom: 0.5em;
}

.Home_home_container__n9\\+KO img {
    width: 350px;
    margin: 2em 0;
}`, "",{"version":3,"sources":["webpack://./src/paginas/Home.module.css"],"names":[],"mappings":"AAAA;IACI,yDAA8C;IAC9C,sBAAsB;IACtB,2BAA2B;IAC3B,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,cAAc;IACd,cAAc;IACd,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,wBAAwB;IACxB,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".home_background {\r\n    background-image: url('../img/BackGround.png');\r\n    background-size: cover;\r\n    background-position: center;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    min-height: 100vh;\r\n}\r\n\r\n.home_container {\r\n    background-color: #5b95f9;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    margin: 12em auto;\r\n    padding: 2.5em;\r\n    min-width: 75%;\r\n    max-width: 80%;\r\n    flex-grow: 1;\r\n}\r\n\r\n.home_container h1 {\r\n    font-size: 2.5em;\r\n    margin-bottom: 1em;\r\n}\r\n\r\n.home_container h1 span {\r\n    color: rgb(35, 131, 227);\r\n    padding: 0.2em;\r\n    background-color: #282c34;\r\n}\r\n\r\n.home_container p {\r\n    margin-bottom: 0.5em;\r\n}\r\n\r\n.home_container img {\r\n    width: 350px;\r\n    margin: 2em 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home_background": `Home_home_background__TbZjr`,
	"home_container": `Home_home_container__n9+KO`
};
export default ___CSS_LOADER_EXPORT___;
