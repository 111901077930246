import React from 'react';
import { useTheme } from '../layout/Theme';
import { FaMoon, FaSun} from 'react-icons/fa';
import styles from './ThemeButton.module.css'

const ThemeButton = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <button class={styles.theme_button} onClick={toggleTheme}>
      {theme === 'light' ? <FaMoon class={styles.theme_moon}/> : <FaSun class={styles.theme_sun}/>}
    </button>
  );
};

export default ThemeButton