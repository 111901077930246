import styles from './ItemList.module.css';
import React from 'react';
import { AiFillCaretDown } from "react-icons/ai";
import { MdExpandMore } from "react-icons/md";
import { MdExpandLess } from "react-icons/md";

const ItemList = ({ item, expandido, onExpandirItem }) => {
  return (
    <li class={styles.list}>
      <div>
        <button class={styles.button} onClick={() => onExpandirItem(item.id)}>
        {expandido ? <p class={styles.namedocs}> <MdExpandLess /> </p> : <p class={styles.namedocs}> <MdExpandMore /> </p>}
        </button>
        <span>{item.titulo}</span>
        {expandido && (
          <>
            <AiFillCaretDown class={styles.iconFill}/>
            {item.conteudo}
          </>
        )}
      </div>
    </li>
  );
};

export default ItemList;