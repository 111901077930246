import styles from './docs.module.css'
import React from 'react';
import DownloadPDF from '../components/evento/Download.js';
import { FcRight } from "react-icons/fc";

const FunctionDocs_2023 = () => {
  const pdfFileName01 = 'Anexo RP-09 - Repasses ao Terceiro Setor - Termo de Ciência e de Notificação - Termo de Colaboração 001_2024.pdf'; // Substitua pelo nome do seu PDF
  const pdfFilePath01 = '/docs/2023/'; // Substitua pelo subdiretório desejado
  
  const pdfFileName02 = 'Anexo RP-09 - Repasses ao Terceiro Setor - Termo de Ciência e Notificação - Termo de Colaboração 023-2023.pdf'; // Substitua pelo nome do seu PDF
  const pdfFilePath02 = '/docs/2023/';

  const pdfFileName03 = 'Ata de Eleição do Quadro de Dirigentes.pdf'; // Substitua pelo nome do seu PDF
  const pdfFilePath03 = '/docs/2023/';

  const pdfFileName04 = 'Certidão de Negativa de Débitos - Estadual.pdf'; // Substitua pelo nome do seu PDF
  const pdfFilePath04 = '/docs/2023/';

  const pdfFileName05 = 'Certidão de Negativa de Débitos - Federal.pdf'; // Substitua pelo nome do seu PDF
  const pdfFilePath05 = '/docs/2023/';

  const pdfFileName06 = 'Certidão de Negativa de Débitos - Municipal.pdf'; // Substitua pelo nome do seu PDF
  const pdfFilePath06 = '/docs/2023/';

  const pdfFileName07 = 'Estatuto da Associação Beneficente Fonte Viva.pdf'; // Substitua pelo nome do seu PDF
  const pdfFilePath07 = '/docs/2023/';

  const pdfFileName08 = 'Plano Aprovado Prefeitura - PNAE.pdf'; // Substitua pelo nome do seu PDF
  const pdfFilePath08 = '/docs/2023/';

  const pdfFileName09 = 'Plano de Trabalho PNAE.pdf'; // Substitua pelo nome do seu PDF
  const pdfFilePath09 = '/docs/2023/';

  const pdfFileName10 = 'Relação Nominal Atualizada dos Dirigentes da Entidade.pdf'; // Substitua pelo nome do seu PDF
  const pdfFilePath10 = '/docs/2023/';

  const pdfFileName11 = 'Termo Aditivo de Termo de Colaboração - PNAE.pdf'; // Substitua pelo nome do seu PDF
  const pdfFilePath11 = '/docs/2023/';

  const pdfFileName12 = 'Termo de Colaboração 001-2024 FUNDEB.pdf'; // Substitua pelo nome do seu PDF
  const pdfFilePath12 = '/docs/2023/';

  const pdfFileName13 = 'Termo de Colaboração 023_2023 PNAE.pdf'; // Substitua pelo nome do seu PDF
  const pdfFilePath13 = '/docs/2023/';

  const pdfFileName14 = 'Termo de Colaboração 028_2023 FUNDEB.pdf'; // Substitua pelo nome do seu PDF
  const pdfFilePath14 = '/docs/2023/';

  return (
    <li className={styles.docs_background}>
      <div className={styles.docs_container}>
      <p className={styles.custom_button}> <FcRight /> {pdfFileName01} <DownloadPDF pdfFileName={pdfFileName01} pdfFilePath={pdfFilePath01} /></p>
        
      <p className={styles.custom_button}> <FcRight /> {pdfFileName02} <DownloadPDF pdfFileName={pdfFileName02} pdfFilePath={pdfFilePath02} /></p>

      <p className={styles.custom_button}> <FcRight /> {pdfFileName03} <DownloadPDF pdfFileName={pdfFileName03} pdfFilePath={pdfFilePath03} /></p>
        
      <p className={styles.custom_button}> <FcRight /> {pdfFileName04} <DownloadPDF pdfFileName={pdfFileName04} pdfFilePath={pdfFilePath04} /></p>

      <p className={styles.custom_button}> <FcRight /> {pdfFileName05} <DownloadPDF pdfFileName={pdfFileName05} pdfFilePath={pdfFilePath05} /></p>
        
      <p className={styles.custom_button}> <FcRight /> {pdfFileName06} <DownloadPDF pdfFileName={pdfFileName06} pdfFilePath={pdfFilePath06} /></p>

      <p className={styles.custom_button}> <FcRight /> {pdfFileName07} <DownloadPDF pdfFileName={pdfFileName07} pdfFilePath={pdfFilePath07} /></p>
        
      <p className={styles.custom_button}> <FcRight /> {pdfFileName08} <DownloadPDF pdfFileName={pdfFileName08} pdfFilePath={pdfFilePath08} /></p>

      <p className={styles.custom_button}> <FcRight /> {pdfFileName09} <DownloadPDF pdfFileName={pdfFileName09} pdfFilePath={pdfFilePath09} /></p>
        
      <p className={styles.custom_button}> <FcRight /> {pdfFileName10} <DownloadPDF pdfFileName={pdfFileName10} pdfFilePath={pdfFilePath10} /></p>

      <p className={styles.custom_button}> <FcRight /> {pdfFileName11} <DownloadPDF pdfFileName={pdfFileName11} pdfFilePath={pdfFilePath11} /></p>
        
      <p className={styles.custom_button}> <FcRight /> {pdfFileName12} <DownloadPDF pdfFileName={pdfFileName12} pdfFilePath={pdfFilePath12} /></p>

      <p className={styles.custom_button}> <FcRight /> {pdfFileName13} <DownloadPDF pdfFileName={pdfFileName13} pdfFilePath={pdfFilePath13} /></p>
        
      <p className={styles.custom_button}> <FcRight /> {pdfFileName14} <DownloadPDF pdfFileName={pdfFileName14} pdfFilePath={pdfFilePath14} /></p>
      
      </div>
    </li>

  );
};

export default FunctionDocs_2023