import styles from './docs.module.css'
import React from 'react';
import DownloadPDF from '../components/evento/Download.js';
import { FcRight } from "react-icons/fc";

const FunctionDocs_2022 = () => {
  const pdfFileName01 = 'Declaração de Remuneração dos Dirigentes 2022.pdf'; // Substitua pelo nome do seu PDF
  const pdfFilePath01 = '/docs/2022/'; // Substitua pelo subdiretório desejado

  const pdfFileName02 = 'Declaração Quanto à Economicidade 2022.pdf';
  const pdfFilePath02 = '/docs/2022/';

  const pdfFileName03 = 'Demonstrativo Integral das Receitas e Despesas 2022.pdf';
  const pdfFilePath03 = '/docs/2022/';

  const pdfFileName04 = 'Plano de Trabalho PNAE 2022.pdf';
  const pdfFilePath04 = '/docs/2022/';

  const pdfFileName05 = 'Relação de Pagamentos 2022.pdf';
  const pdfFilePath05 = '/docs/2022/';

  const pdfFileName06 = 'Relação Nominal dos Dirigentes 2022.pdf';
  const pdfFilePath06 = '/docs/2022/';

  const pdfFileName07 = 'Relatorio de Execução Fisica do Objeto PNAE 2022.pdf';
  const pdfFilePath07 = '/docs/2022/';

  const pdfFileName08 = 'Termo de Colaboração PNAE 2022.pdf';
  const pdfFilePath08 = '/docs/2022/';

  return (
    <li className={styles.docs_background}>
      <div className={styles.docs_container}>
        <p className={styles.custom_button}> <FcRight /> {pdfFileName01} <DownloadPDF pdfFileName={pdfFileName01} pdfFilePath={pdfFilePath01} /></p>
        
        <p className={styles.custom_button}> <FcRight /> {pdfFileName02} <DownloadPDF pdfFileName={pdfFileName02} pdfFilePath={pdfFilePath02} /></p>

        <p className={styles.custom_button}> <FcRight /> {pdfFileName03} <DownloadPDF pdfFileName={pdfFileName03} pdfFilePath={pdfFilePath03} /></p>
        
        <p className={styles.custom_button}> <FcRight /> {pdfFileName04} <DownloadPDF pdfFileName={pdfFileName04} pdfFilePath={pdfFilePath04} /></p>

        <p className={styles.custom_button}> <FcRight /> {pdfFileName05} <DownloadPDF pdfFileName={pdfFileName05} pdfFilePath={pdfFilePath05} /></p>
        
        <p className={styles.custom_button}> <FcRight /> {pdfFileName06} <DownloadPDF pdfFileName={pdfFileName06} pdfFilePath={pdfFilePath06} /></p>

        <p className={styles.custom_button}> <FcRight /> {pdfFileName07} <DownloadPDF pdfFileName={pdfFileName07} pdfFilePath={pdfFilePath07} /></p>
        
        <p className={styles.custom_button}> <FcRight /> {pdfFileName08} <DownloadPDF pdfFileName={pdfFileName08} pdfFilePath={pdfFilePath08} /></p>
      </div>
    </li>

  );
};

export default FunctionDocs_2022
