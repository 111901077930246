import React, { useState } from 'react';
import styles from './Login.module.css';
import {Link} from 'react-router-dom'
import { FaHome } from "react-icons/fa";

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Adicione aqui a lógica para autenticação ou envio dos dados para o servidor
    console.log('Usuário:', username);
    console.log('Senha:', password);
  };

  return (

    <div>
      <div class={styles.path_border}>
          <li class={styles.path}>
              <Link to="/"><FaHome/>Inicio</Link><span> / Login</span>
          </li>
      </div>

      <li className={styles.Login_background}>
        <div className={styles.Login_container}>
          <div className={styles.login_form_container}>
            <h1>
              Área de Login
            </h1>
            <form onSubmit={handleSubmit}>
            <div className={styles.input_container}>
              <label htmlFor="username">Usuário:</label>
              <input
                type="text"
                id="username"
              value={username}
                onChange={handleUsernameChange}
              />
            </div>
            <div className={styles.input_container}>
              <label htmlFor="password">Senha:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={handlePasswordChange}
              />
          </div>
          <button type="submit">Entrar</button>
          </form>
          </div>
        </div>

      </li>
    </div>
  
  );
};

export default LoginForm;