// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Download_item__lz46B {
    margin-left: 5mm;
    margin-bottom: 5mm;
    list-style: none;
    align-items: center;
}

.Download_button__u6dJ9 {
    border: none;
    background: none;
    padding: 0;
    color: black;
    font-size: 20px;
}

.Download_download__oO0AO{
    color: black;
    font-size: 20px;
    cursor: pointer;
}

.Download_download__oO0AO:hover{
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/evento/Download.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,UAAU;IACV,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".item {\r\n    margin-left: 5mm;\r\n    margin-bottom: 5mm;\r\n    list-style: none;\r\n    align-items: center;\r\n}\r\n\r\n.button {\r\n    border: none;\r\n    background: none;\r\n    padding: 0;\r\n    color: black;\r\n    font-size: 20px;\r\n}\r\n\r\n.download{\r\n    color: black;\r\n    font-size: 20px;\r\n    cursor: pointer;\r\n}\r\n\r\n.download:hover{\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `Download_item__lz46B`,
	"button": `Download_button__u6dJ9`,
	"download": `Download_download__oO0AO`
};
export default ___CSS_LOADER_EXPORT___;
